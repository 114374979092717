<template>

  <!-- Content container -->
  <div v-if="learnerAndUserProfileDetails && learnerAndUserProfileDetails.learners && learnerAndUserProfileDetails.learners.length > 0"
    class="px-4 py-8 overflow-x-auto" style="height: 100%; width: 100%;">

    <div class="hidden md:flex text-sm md:text-xl font-normal mb-3 md:mb-6 md:ml-10">Hello! {{ learnerAndUserProfileDetails.user_details.first_name }}, what would
      you like to see today ? </div>
      
    <!-- Widgets for desktop (previously used for both desktop and mobile so responsive styling remains)-->
    <div class="hidden md:flex flex-wrap justify-center md:justify-start md:ml-10 gap-x-4 md:gap-y-4 md:gap-x-8">

      <!-- First column -->
      <div class="w-full md:w-auto">
        
        <!-- New Schedule Widget -->
        <div class="relative md:w-[370px] max-h-[270px] md:max-h-none md:h-auto border border-slate-200 rounded-xl mb-6 md:mb-7 pt-4 pb-2.5 overflow-auto md:overflow-hidden">
          
          <!-- heading -->
          <div class="flex justify-between items-center pb-2 px-5">
            <div class="font-medium">
              Learners Schedule
            </div>
            <router-link to="/front-portal/calendar" class="text-white bg-black rounded text-center text-xs py-2 px-3">View All</router-link>
          </div>   

          <!-- Rendering schedule events/activities -->
          <div ref="scheduleContainer" class="h-auto overflow-hidden">
            <div v-if="Object.keys(groupedEvents).length > 0">
              <div v-for="(dateEvents, date) in groupedEvents" :key="date" class="flex py-1.5 justify-center">
                
                <!-- Date/Day -->
                <div class="w-[10%] border rounded-lg text-center justify-center mr-1 flex flex-col bg-[#FFFAE6]"
                  :class="{ 'bg-[#fdd835]': isToday(date), 'bg-[#FFFAE6]': !isToday(date) }">
                    <div class="text-xs font-medium"> {{ moment(date).format('ddd') }}</div>
                    <div class="font-semibold text-xl">  {{ moment(date).format('DD') }}</div>
                </div>

                <!-- Activities on that day -->
                <div class="border border-slate-200 rounded-lg p-2 w-[80%] overflow-hidden">
                  
                  <div v-if="Object.keys(dateEvents).length > 0" class="">
                
                    <div v-for="(cohortEvents, cohort) in dateEvents" :key="cohort" class="md:w-[270px] min-h-[31px]">
                          <h3 v-if="cohortEvents.Morning.length>0 || cohortEvents.Afternoon.length>0" class="text-xs font-semibold px-1.5 py-0.5 rounded-[3px] bg-[#FFEFA6] inline-flex mb-2">{{ cohort }}</h3>
                            
                          <div v-for="session in ['Morning', 'Afternoon']" :key="session" class="flex flex-start mb-1.5">
                            
                              <div v-if="session == 'Morning' && cohortEvents[session].length>0" class="mr-1 text-xs p-[2px] font-medium bg-[#DEF9FF] rounded-[3.7px]">AM</div>
                            
                              <div v-if="session =='Afternoon' && cohortEvents[session].length>0" class="mr-1 text-xs p-[2px] font-medium bg-[#FFDDDD] rounded-[3.7px]">PM</div>

                              <!-- div for invitation to play -->
                              <!-- div for circle time -->
                            
                              <div v-for="event in cohortEvents[session]" :key="event.name" class="text-sm ml-1 w-full truncate">
                              {{ event.activity_title || event.activity_name }}
                              </div>
                          
                          </div>
                        </div>

                    </div>  


                    <div v-else class="w-[270px] h-[60px] flex items-center">
                        <div class="text-sm ml-4 truncate max-w-[180px] text-slate-500">No events for the day</div>
                    </div>
                  
                </div>

              </div>
            </div>
          </div>
        </div>

        <!--Lunch Menu Card -->
        <div class="md:w-[370px] h-auto border border-slate-200 rounded-xl overflow-hidden px-4 pt-4 mt-6">
            <!--Header row  -->
            <div class="flex justify-between mb-1">
              <div class="font-medium mr-2">
                Lunch Menu
              </div>
              <router-link to="/front-portal/lunch-menu"                         
              class="text-white bg-black rounded text-center text-xs py-2 px-3"> 
                View All
              </router-link>
            </div>
            <div v-if="lunchMenuData && lunchMenuData.data && lunchMenuData.data.menu.length > 0" class="flex flex-wrap items-start mb-4 gap-y-2 items-center">
              

              <div v-for="(menuItem, index) in lunchMenuData.data.menu" :key="index" class="w-full mt-2 mx-2">
                <template v-if="index<=1">
                  <!-- Menu Tag -->
                  <div v-if="menuItem.tag" class="bg-[#FEF3C2] rounded-t-xl text-sm text-center inline-flex px-3 py-[3px] ml-3 max-w-[110px] truncate">{{ menuItem.tag }}</div>
                  
                  <!-- Body container -->
                  <div class="flex border border-slate-200 min-h-[120px] w-full rounded-xl">
                    
                    <!-- Date column-->
                    <div class="flex font-medium bg-[#FEF3C2] max-w-[33%] rounded-l-xl w-full text-sm justify-center px-2 text-slate-500">   
                      
                      <!-- Content -->
                      <div class="flex flex-col items-center justify-center">
                        
                        <!-- Calendar symbol with day-->
                        <div class="flex items-center">
                          <svg width="19" height="18" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.6205 3.22437H11.3811V2.32354C11.3811 2.15743 11.2341 1.9977 11.0616 2.00409C10.8891 2.01048 10.7422 2.14465 10.7422 2.32354V3.22437H6.26995V2.32354C6.26995 2.15743 6.123 1.9977 5.9505 2.00409C5.778 2.01048 5.63106 2.14465 5.63106 2.32354V3.22437H4.398C3.97439 3.22437 3.56813 3.39265 3.2686 3.69218C2.96906 3.99172 2.80078 4.39798 2.80078 4.82159V11.8494C2.80078 12.273 2.96906 12.6792 3.2686 12.9788C3.56813 13.2783 3.97439 13.4466 4.398 13.4466H12.6205C13.0441 13.4466 13.4504 13.2783 13.7499 12.9788C14.0494 12.6792 14.2177 12.273 14.2177 11.8494V4.82159C14.2177 4.39798 14.0494 3.99172 13.7499 3.69218C13.4504 3.39265 13.0441 3.22437 12.6205 3.22437ZM13.5788 11.8494C13.5788 12.3796 13.1508 12.8077 12.6205 12.8077H4.398C3.86773 12.8077 3.43967 12.3796 3.43967 11.8494V6.46992H13.5788V11.8494ZM13.5788 5.83104H3.43967V4.82159C3.43967 4.29131 3.86773 3.86326 4.398 3.86326H5.63106V4.2402C5.63106 4.40631 5.778 4.56604 5.9505 4.55965C6.123 4.55326 6.26995 4.41909 6.26995 4.2402V3.86326H10.7422V4.2402C10.7422 4.40631 10.8891 4.56604 11.0616 4.55965C11.2341 4.55326 11.3811 4.41909 11.3811 4.2402V3.86326H12.6205C13.1508 3.86326 13.5788 4.29131 13.5788 4.82159V5.83104Z" fill="#6B7280"/>
                            <path d="M8.1847 11.0292V8.81865C8.1847 8.81015 8.18243 8.8018 8.17813 8.79447C8.17383 8.78713 8.16766 8.78107 8.16025 8.77691C8.15283 8.77275 8.14444 8.77063 8.13594 8.77078C8.12744 8.77093 8.11913 8.77334 8.11187 8.77776L7.70426 9.02821C7.68974 9.03705 7.67312 9.04187 7.65612 9.04217C7.63912 9.04247 7.62234 9.03824 7.60751 9.02992C7.59269 9.02159 7.58034 9.00947 7.57174 8.9948C7.56315 8.98013 7.55861 8.96343 7.55859 8.94643V8.53051C7.55859 8.46343 7.59373 8.40082 7.65187 8.36632L8.13998 8.07435C8.16969 8.05646 8.20369 8.04696 8.23837 8.04688H8.63832C8.66349 8.04687 8.68841 8.05183 8.71166 8.06146C8.73492 8.0711 8.75605 8.08521 8.77384 8.10301C8.79164 8.12081 8.80576 8.14194 8.81539 8.16519C8.82503 8.18845 8.82998 8.21337 8.82998 8.23854V11.0286C8.82998 11.0794 8.80979 11.1282 8.77384 11.1641C8.7379 11.2 8.68915 11.2202 8.63832 11.2202H8.37701C8.35179 11.2205 8.32678 11.2157 8.30341 11.2062C8.28005 11.1967 8.25881 11.1827 8.24092 11.1649C8.22303 11.1472 8.20884 11.126 8.19919 11.1027C8.18954 11.0794 8.18462 11.0544 8.1847 11.0292Z" fill="#6B7280"/>
                          </svg>
                          {{ moment(menuItem.date).format('ddd') }}
                        </div>  

                        <!-- Date -->
                        <div class="mt-1">{{ moment(menuItem.date).format('Do MMM') }}</div>

                      </div>
                      
                    </div>

                    <!-- Items column -->
                    <ul class="list-disc pl-6 mt-3 mb-1">
                      <li v-for="(item, itemIndex) in menuItem.menu_items" :key="itemIndex" class="mb-1.5 mr-1 text-[13px] font-medium">
                        {{ item.menu_item }}
                      </li>
                    </ul>


                  </div> 
                </template>     
              </div>
            </div>
        </div>

        <!-- Micro Learnings Card-->
        <div v-if="sortedQuizList.length>0" class="md:w-[370px] h-auto border border-slate-200 rounded-xl overflow-hidden px-4 py-4 mt-6">
          <!--Header row  -->
          <div class="flex justify-between mb-3">
            <div class="font-medium mr-2">
              Micro Learning
            </div>
            <router-link to="/front-portal/educators"                         
            class="text-white bg-black rounded text-center text-xs py-2 px-3"> 
              View All
            </router-link>
          </div>

          <!-- Render list of quetionnairs -->
          <div v-if="sortedQuizList" class="flex flex-col md:flex-row md:flex-wrap gap-4">
            <LearningCard v-for="quiz in sortedQuizList.slice(0, 2)" :key="quiz.name" :title="quiz.title"
              :description="quiz.description" :qID="quiz.name" :updateScore="setLastScore" class="text-sm mb- learning-card-wrapper"/>
          </div>
        </div>    

      </div>

      <!-- Second column -->
      <div class="w-full md:w-auto mt-6 md:mt-0 md:min-w-[350px]">

          <!-- Observations Card -->  <!--Temporary absolute width for md and lg screens to help style the layout-->
          <!-- <div class=" md:w-[400px] h-[350px] border border-slate-200 rounded-xl mb-6">
            
            <div class="flex">
              <div class="font-medium mr-2 mt-4 ml-4">
                {{ learnerAndUserProfileDetails.learners[0].student_name }} &
                {{ learnerAndUserProfileDetails.learners[1].student_name }}'s Observations
              </div>

              <router-link to="/front-portal/observations/view/child/all"                         
                class="text-white bg-black rounded self-center text-center w-[22%] text-xs py-1.5 mr-4"> View All</router-link>
            </div> -->


          <!-- Daily Observations widgets -->
          <!-- <div v-if="filteredObservations.length > 0">
            <div v-for="(observation, index) in filteredObservations" :key="observation.name" class="p-3 border m-5 rounded-lg">
              <div class="font-medium mb-1"> {{ observation.student_name }}</div>
                <div class="flex gap-2">
                  <div class="text-slate-500 font-medium text-sm mt-1">{{ moment(observation.creation).format('DD/MM/YY |') }}</div>
                  <div class="text-slate-500 font-medium text-medium">{{ observation.educator_name1 }}</div>
                </div>
                <div class="">{{ observation.anecdotes }}</div>
            </div>
          </div>

        </div> -->

       <!-- Learner profiles cards -->
       <div class="flex-col w-full md:w-[370px] h-auto max-h-[300px] overflow-auto border border-slate-200 rounded-xl px-4 py-4 mb-6">
            <div class="font-medium mb-4">Learners Profile</div>

            <!-- Learner -->
            <div v-for="(learner, index) in learnerAndUserProfileDetails.learners" :key="index">
              <router-link :to="{ path: '/front-portal/learners', query: { learner: learner.name}}"  class="flex gap-2 border border-slate-200 pl-4 rounded-lg pt-3 pb-3 mt-4">                          
                  <!-- pfp -->
                  <img :src=learner.presigned_s3_url class="rounded-full w-16 h-16" alt="Profile Picture">
                  <!-- Info -->
                  <div>
                    <!-- Name -->
                    <span class="font-medium mr-2 text-sm">{{ learner.student_name }}</span>

                    <!-- Age -->
                    <div>
                      <span class="font-medium mr-2 text-sm">Age:</span>
                      <span class="text-slate-500 text-sm">{{ calculateAge(learner.date_of_birth)}}</span>
                    </div>

                    <!-- Cohort -->
                    <div>
                      <span class="font-medium mr-2 text-sm">Cohort:</span>
                      <span class="text-slate-500 text-sm">{{ learner.cohort_name }}</span>
                    </div>
                  </div>
              </router-link>
            </div>

        </div>
          
        <!-- Photo essays Widget for smaller desktop screens -->
        <div v-if="weeklyImagesData && weeklyImagesData.length > 0" class="flex flex-col show-photo-essays-widget border border-slate-200 px-4 py-4 md:w-[380px] md:max-h-[20rem] rounded-xl mb-6">
              <div class="flex md:mt-3 justify-between">
              <!-- Name heading -->
              <div class="font-medium mr-8 ml-2 text-normal">
                Photo Essays
              </div>

              <router-link 
                  :to="{ 
                    path: '/front-portal/photo-essays', 
                    query: { learners: getLearnerNames() }
                  }" 
                  class="text-white bg-black rounded text-center text-xs py-2 px-3">
                  View All
              </router-link>
              </div>

              <!-- Tags -->
              <div v-if="uniqueTags" class="tags-container flex mt-2">
                <div v-for="(tag, tagIndex) in uniqueTags" :key="`tag-${tagIndex}`">
                  <span class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-md px-1.5 py-1 text-xs font-medium text-black"
                    style="background-color: #FDD835;">
                    {{ tag }}
                  </span>

                </div>
              </div>

              <!-- Images -->
              <div class="flex flex-col border-slate-200 rounded-md items-center md:pl-[25px] md:p-2 cursor-pointer">
                <div class="flex flex-wrap relative min-h-[10rem] min-w-[16rem] md:min-w-[18rem] items-center">
                  <!-- Render the images if present -->
                  <img v-if="weeklyImagesData && weeklyImagesData.length > 0 && weeklyImagesData[0].thumbnail_url"
                    :src="weeklyImagesData[0].thumbnail_url" class="rounded-3xl absolute left-[0px] md:left-[0px]"
                    style="z-index: 1; height: 125px !important;" width="125" />

                  <img v-if="weeklyImagesData[1] && weeklyImagesData.length > 1 && weeklyImagesData[1].thumbnail_url"
                    :src="weeklyImagesData[1].thumbnail_url" class="rounded-3xl absolute left-[25%] md:left-[85px]"
                    style="z-index: 2; outline: 8px solid white; height: 125px !important;" width="125" />

                  <img v-if="weeklyImagesData[2] && weeklyImagesData.length > 2 && weeklyImagesData[2].thumbnail_url"
                    :src="weeklyImagesData[2].thumbnail_url" class="rounded-3xl absolute left-[50%] md:left-[170px]"
                    style="z-index: 3; outline: 8px solid white; height: 125px !important;" width="125" />

                  <!-- Semi-transparent black cover for the 3rd image -->
                  <div v-if="weeklyImagesData.length > 3"
                    class="rounded-3xl absolute bg-black bg-opacity-50 left-[50%] md:left-[170px]"
                    style="z-index: 4; height: 125px; width: 125px;"></div>

                  <!-- Image count on top of black cover  -->
                  <div v-if="weeklyImagesData.length > 3" class="absolute z-10 text-white text-5xl"
                    style="left: calc(180px + 70px - 50px); top: 57px;">
                    +{{ weeklyImagesData.length - 3 }}
                  </div>
                </div>

                <!-- Description -->
                <!-- <div class="text-slate-600">{{ weeklyImagesData[0] && weeklyImagesData[0].description }}</div> -->
              </div>

        </div>

        <!--Learner Reports Card -->
        <div v-if="learnerAndUserProfileDetails.learners.length>0 && learnerAndUserProfileDetails.learners[0].report_status.is_published === 1" class="border border-slate-200 rounded-xl px-4 py-4 mb-6">
           
           <!-- Header row -->
           <div class="flex justify-between">
             <div class="font-medium">
               Learners Reports
             </div>
             <router-link to="/front-portal/observations/report"                         
               class="text-white bg-black rounded text-center text-xs py-2 px-3"> 
                 View All
             </router-link>
            </div>
 
            <!-- Reports button -->
             <div v-for="(learner, index) in learnerAndUserProfileDetails.learners">
                <template v-if="learner.report_status.is_published === 1"> 
                 <router-link :to="{ name: 'StudentReport', params: { student: learner.name } }" class="flex gap-2 border border-slate-200 pl-4 rounded-lg  py-5 mt-4">
                   <div class="font-medium mr-2 text-sm"> {{ learner.student_name }}'s Report</div>
                 </router-link>
               </template> 
             </div>
 
        </div> 

         <!-- Worksheet widget -->
         <div v-if="worksheetTitles.length>0" class="hide-worksheet-widget show-worksheet-widget flex-col border border-slate-200 rounded-xl overflow-auto px-4 py-4">
          
          <!--Header row  -->
          <div class="flex justify-between">
            <div class="font-medium mr-2">
              Worksheets
            </div>
            <router-link to="/front-portal/worksheets"                         
             class="text-white bg-black rounded text-center text-xs py-2 px-3"> 
              View All
            </router-link>
          </div>

          <!-- Worksheet titles -->
          <!-- <template v-if="worksheetTitles"> -->
            <div v-for="(worksheet, index) in worksheetTitles" :key="index">
              <router-link :to="{ name: 'WorksheetDetails', params: { worksheetName: worksheet.worksheet_id } }" class="flex gap-2 border border-slate-200 pl-4 rounded-lg  py-5 mt-4">
              <!-- Worksheet title-->
              <div class="font-medium mr-2 text-sm">{{ worksheet.title }}</div>
              </router-link>
            </div>
          <!-- </template>    -->

        </div> 

      </div>

      <!-- Third column -->
      <div class="w-full md:w-auto mt-6 md:mt-0">

        <!-- Photo essays Widget -->
        <div v-if="weeklyImagesData && weeklyImagesData.length > 0" class="hide-widget flex flex-col border border-slate-200 px-4 py-4 md:w-[380px] md:max-h-[20rem] rounded-xl mb-6">

          <div class="flex md:mt-3 justify-between">
            <!-- Name heading -->
            <div class="font-medium mr-8 ml-2 text-normal">
              Photo Essays
            </div>

            <router-link 
                :to="{ 
                  path: '/front-portal/photo-essays', 
                  query: { learners: getLearnerNames() }
                }" 
                class="text-white bg-black rounded text-center text-xs py-2 px-3">
                View All
            </router-link>
          </div>

          <!-- Tags -->
          <div v-if="uniqueTags" class="tags-container flex mt-2">
            <div v-for="(tag, tagIndex) in uniqueTags" :key="`tag-${tagIndex}`">
              <span class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-md px-1.5 py-1 text-xs font-medium text-black"
                style="background-color: #FDD835;">
                {{ tag }}
              </span>

            </div>
          </div>

          <!-- Images -->
          <div class="flex flex-col border-slate-200 rounded-md items-center md:pl-[25px] md:p-2 cursor-pointer">
            <div class="flex flex-wrap relative min-h-[10rem] min-w-[16rem] md:min-w-[18rem] items-center">
              <!-- Render the images if present -->
              <img v-if="weeklyImagesData && weeklyImagesData.length > 0 && weeklyImagesData[0].thumbnail_url"
                :src="weeklyImagesData[0].thumbnail_url" class="rounded-3xl absolute left-[0px] md:left-[0px]"
                style="z-index: 1; height: 125px !important;" width="125" />

              <img v-if="weeklyImagesData[1] && weeklyImagesData.length > 1 && weeklyImagesData[1].thumbnail_url"
                :src="weeklyImagesData[1].thumbnail_url" class="rounded-3xl absolute left-[25%] md:left-[85px]"
                style="z-index: 2; outline: 8px solid white; height: 125px !important;" width="125" />

              <img v-if="weeklyImagesData[2] && weeklyImagesData.length > 2 && weeklyImagesData[2].thumbnail_url"
                :src="weeklyImagesData[2].thumbnail_url" class="rounded-3xl absolute left-[50%] md:left-[170px]"
                style="z-index: 3; outline: 8px solid white; height: 125px !important;" width="125" />

              <!-- Semi-transparent black cover for the 3rd image -->
              <div v-if="weeklyImagesData.length > 3"
                class="rounded-3xl absolute bg-black bg-opacity-50 left-[50%] md:left-[170px]"
                style="z-index: 4; height: 125px; width: 125px;"></div>

              <!-- Image count on top of black cover  -->
              <div v-if="weeklyImagesData.length > 3" class="absolute z-10 text-white text-5xl"
                style="left: calc(180px + 70px - 50px); top: 57px;">
                +{{ weeklyImagesData.length - 3 }}
              </div>
            </div>

            <!-- Description -->
            <!-- <div class="text-slate-600">{{ weeklyImagesData[0] && weeklyImagesData[0].description }}</div> -->
          </div>

        </div>


        <!-- Worksheet widget -->
        <div v-if="worksheetTitles.length>0" class="hide-widget border border-slate-200 rounded-xl overflow-auto px-4 py-4">
          
          <!--Header row  -->
          <div class="flex justify-between">
            <div class="font-medium mr-2">
              Worksheets
            </div>
            <router-link to="/front-portal/worksheets"                         
             class="text-white bg-black rounded text-center text-xs py-2 px-3"> 
              View All
            </router-link>
          </div>

          <!-- Worksheet titles -->
          <!-- <template v-if="worksheetTitles"> -->
            <div v-for="(worksheet, index) in worksheetTitles" :key="index">
              <router-link :to="{ name: 'WorksheetDetails', params: { worksheetName: worksheet.worksheet_id } }" class="flex gap-2 border border-slate-200 pl-4 rounded-lg  py-5 mt-4">
              <!-- Worksheet title-->
              <div class="font-medium mr-2 text-sm">{{ worksheet.title }}</div>
              </router-link>
            </div>
          <!-- </template>    -->

        </div> 

      </div>

    </div>

 
    <!-- Widgets For Mobile -->
    <div class="flex md:hidden flex-wrap justify-center md:justify-start md:ml-10 gap-x-4  md:gap-y-4 lg:gap-x-8">
        
      <!-- First column -->
      <div class="w-full md:w-auto">
          
          <!-- New Schedule Widget -->
          <div class="relative md:w-[370px] max-h-[270px] md:max-h-none md:h-auto border border-slate-200 rounded-xl mb-6 md:mb-7 pt-4 pb-2.5 overflow-auto md:overflow-hidden">
            
            <!-- heading -->
            <div class="flex justify-between items-center pb-2 px-4">
              <div class="font-medium">
                Learners Schedule
              </div>
              
              <!-- date, toggle and view all button -->
              <div class="flex items-center justify-between">   
                  <router-link to="/front-portal/calendar" class="text-white bg-black rounded text-center text-xs py-2 px-3">View All</router-link>
              </div> 

            </div>
              <!-- Rendering schedule events/activities -->
              <div ref="scheduleContainer" class="h-auto overflow-hidden">
                <div v-if="Object.keys(groupedEvents).length > 0">
                  <div v-for="(dateEvents, date) in groupedEvents" :key="date" class="flex py-1.5 justify-center">
                    
                    <!-- Date/Day -->
                    <div class="w-[10%] border rounded-lg text-center justify-center mr-1 flex flex-col bg-[#FFFAE6]"
                      :class="{ 'bg-[#fdd835]': isToday(date), 'bg-[#FFFAE6]': !isToday(date) }">
                        <div class="text-xs font-medium"> {{ moment(date).format('ddd') }}</div>
                        <div class="font-semibold text-xl">  {{ moment(date).format('DD') }}</div>
                    </div>

                    <!-- Activities on that day -->
                    <div class="border border-slate-200 rounded-lg p-2 w-[80%] overflow-hidden">
                      
                      <div v-if="Object.keys(dateEvents).length > 0" class="">
                    
                        <div v-for="(cohortEvents, cohort) in dateEvents" :key="cohort" class="md:w-[270px] min-h-[31px]">
                              <h3 v-if="cohortEvents.Morning.length>0 || cohortEvents.Afternoon.length>0" class="text-xs font-semibold px-1.5 py-0.5 rounded-[3px] bg-[#FFEFA6] inline-flex mb-2">{{ cohort }}</h3>
                                
                              <div v-for="session in ['Morning', 'Afternoon']" :key="session" class="flex flex-start mb-2">
                                
                                  <div v-if="session == 'Morning' && cohortEvents[session].length>0" class="mr-1 text-xs p-[2px] font-medium bg-[#DEF9FF] rounded-[3.7px]">AM</div>
                                
                                  <div v-if="session =='Afternoon' && cohortEvents[session].length>0" class="mr-1 text-xs p-[2px] font-medium bg-[#FFDDDD] rounded-[3.7px]">PM</div>

                                  <!-- div for invitation to play -->
                                  <!-- div for circle time -->
                                
                                  <div v-for="event in cohortEvents[session]" :key="event.name" class="text-sm ml-1 w-full truncate">
                                  {{ event.activity_title || event.activity_name }}
                                  </div>
                              
                              </div>
                            </div>

                        </div>  


                        <div v-else class="w-[270px] h-[60px] flex items-center">
                            <div class="text-sm ml-4 truncate max-w-[180px] text-slate-500">No events for the day</div>
                        </div>
                      
                    </div>

                  </div>
                </div>
              </div>
          </div>

          <!--Lunch Menu Card -->
          <div class="md:w-[370px] h-auto border border-slate-200 rounded-xl overflow-hidden px-4 pt-4 mt-6">

            <!--Header row  -->
            <div class="flex justify-between mb-1">
              <div class="font-medium mr-2">
                Lunch Menu
              </div>
              <router-link to="/front-portal/lunch-menu"                         
              class="text-white bg-black rounded text-center text-xs py-2 px-3"> 
                View All
              </router-link>
            </div>

            <!-- Lunch Menu cards container -->
            <div v-if="lunchMenuData && lunchMenuData.data && lunchMenuData.data.menu.length > 0" class="flex flex-wrap items-start mb-4 gap-y-2 items-center">
              <div v-for="(menuItem, index) in lunchMenuData.data.menu" :key="index" class="w-full md:max-w-[220px] mt-2 mx-2">
                <template v-if="index<=1">
                  <!-- Menu Tag -->
                  <div v-if="menuItem.tag" class="bg-[#FEF3C2] rounded-t-xl text-sm text-center inline-flex px-3 py-[3px] ml-3 max-w-[110px] truncate">{{ menuItem.tag }}</div>
                  
                  <!-- Body container -->
                  <div class="flex border border-slate-200 min-h-[120px] w-full rounded-xl">
                    
                    <!-- Date column-->
                    <div class="flex font-medium bg-[#FEF3C2] max-w-[33%] rounded-l-xl w-full text-sm justify-center px-2 text-slate-500">   
                      
                      <!-- Content -->
                      <div class="flex flex-col items-center justify-center">
                        
                        <!-- Calendar symbol with day-->
                        <div class="flex items-center">
                          <svg width="19" height="18" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.6205 3.22437H11.3811V2.32354C11.3811 2.15743 11.2341 1.9977 11.0616 2.00409C10.8891 2.01048 10.7422 2.14465 10.7422 2.32354V3.22437H6.26995V2.32354C6.26995 2.15743 6.123 1.9977 5.9505 2.00409C5.778 2.01048 5.63106 2.14465 5.63106 2.32354V3.22437H4.398C3.97439 3.22437 3.56813 3.39265 3.2686 3.69218C2.96906 3.99172 2.80078 4.39798 2.80078 4.82159V11.8494C2.80078 12.273 2.96906 12.6792 3.2686 12.9788C3.56813 13.2783 3.97439 13.4466 4.398 13.4466H12.6205C13.0441 13.4466 13.4504 13.2783 13.7499 12.9788C14.0494 12.6792 14.2177 12.273 14.2177 11.8494V4.82159C14.2177 4.39798 14.0494 3.99172 13.7499 3.69218C13.4504 3.39265 13.0441 3.22437 12.6205 3.22437ZM13.5788 11.8494C13.5788 12.3796 13.1508 12.8077 12.6205 12.8077H4.398C3.86773 12.8077 3.43967 12.3796 3.43967 11.8494V6.46992H13.5788V11.8494ZM13.5788 5.83104H3.43967V4.82159C3.43967 4.29131 3.86773 3.86326 4.398 3.86326H5.63106V4.2402C5.63106 4.40631 5.778 4.56604 5.9505 4.55965C6.123 4.55326 6.26995 4.41909 6.26995 4.2402V3.86326H10.7422V4.2402C10.7422 4.40631 10.8891 4.56604 11.0616 4.55965C11.2341 4.55326 11.3811 4.41909 11.3811 4.2402V3.86326H12.6205C13.1508 3.86326 13.5788 4.29131 13.5788 4.82159V5.83104Z" fill="#6B7280"/>
                            <path d="M8.1847 11.0292V8.81865C8.1847 8.81015 8.18243 8.8018 8.17813 8.79447C8.17383 8.78713 8.16766 8.78107 8.16025 8.77691C8.15283 8.77275 8.14444 8.77063 8.13594 8.77078C8.12744 8.77093 8.11913 8.77334 8.11187 8.77776L7.70426 9.02821C7.68974 9.03705 7.67312 9.04187 7.65612 9.04217C7.63912 9.04247 7.62234 9.03824 7.60751 9.02992C7.59269 9.02159 7.58034 9.00947 7.57174 8.9948C7.56315 8.98013 7.55861 8.96343 7.55859 8.94643V8.53051C7.55859 8.46343 7.59373 8.40082 7.65187 8.36632L8.13998 8.07435C8.16969 8.05646 8.20369 8.04696 8.23837 8.04688H8.63832C8.66349 8.04687 8.68841 8.05183 8.71166 8.06146C8.73492 8.0711 8.75605 8.08521 8.77384 8.10301C8.79164 8.12081 8.80576 8.14194 8.81539 8.16519C8.82503 8.18845 8.82998 8.21337 8.82998 8.23854V11.0286C8.82998 11.0794 8.80979 11.1282 8.77384 11.1641C8.7379 11.2 8.68915 11.2202 8.63832 11.2202H8.37701C8.35179 11.2205 8.32678 11.2157 8.30341 11.2062C8.28005 11.1967 8.25881 11.1827 8.24092 11.1649C8.22303 11.1472 8.20884 11.126 8.19919 11.1027C8.18954 11.0794 8.18462 11.0544 8.1847 11.0292Z" fill="#6B7280"/>
                          </svg>
                          {{ moment(menuItem.date).format('ddd') }}
                        </div>  

                        <!-- Date -->
                        <div class="mt-1">{{ moment(menuItem.date).format('Do MMM') }}</div>

                      </div>
                      
                    </div>

                    <!-- Items column -->
                    <ul class="list-disc pl-6 mt-3 mb-1">
                      <li v-for="(item, itemIndex) in menuItem.menu_items" :key="itemIndex" class="mb-1.5 mr-1 text-[13px] font-medium">
                        {{ item.menu_item }}
                      </li>
                    </ul>


                  </div> 
                </template>     
              </div>
            </div>

          </div>

      </div>

        <!-- Second column -->
        <div class="w-full md:w-auto mt-6 md:mt-0">

           <!-- Photo essays Widget -->
           <div v-if="weeklyImagesData && weeklyImagesData.length > 0" class="flex flex-col border border-slate-200 px-4 py-4 md:w-[380px] md:max-h-[20rem] rounded-xl">

            <div class="flex md:mt-3 justify-between">
              <!-- Name heading -->
              <div class="font-medium mr-8 ml-2 text-normal">
                Photo Essays
              </div>

              <router-link 
                  :to="{ 
                    path: '/front-portal/photo-essays', 
                    query: { learners: getLearnerNames() }
                  }" 
                  class="text-white bg-black rounded text-center text-xs py-2 px-3">
                  View All
              </router-link>
            </div>

            <!-- Tags -->
            <div v-if="uniqueTags" class="tags-container flex mt-2">
              <div v-for="(tag, tagIndex) in uniqueTags" :key="`tag-${tagIndex}`">
                <span class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-md px-1.5 py-1 text-xs font-medium text-black"
                  style="background-color: #FDD835;">
                  {{ tag }}
                </span>

              </div>
            </div>

            <!-- Images -->
            <div class="flex flex-col border-slate-200 rounded-md items-center md:pl-[25px] md:p-2 cursor-pointer">
              <div class="flex flex-wrap relative min-h-[10rem] min-w-[16rem] md:min-w-[18rem] items-center">
                <!-- Render the images if present -->
                <img v-if="weeklyImagesData && weeklyImagesData.length > 0 && weeklyImagesData[0].thumbnail_url"
                  :src="weeklyImagesData[0].thumbnail_url" class="rounded-3xl absolute left-[0px] md:left-[0px]"
                  style="z-index: 1; height: 125px !important;" width="125" />

                <img v-if="weeklyImagesData[1] && weeklyImagesData.length > 1 && weeklyImagesData[1].thumbnail_url"
                  :src="weeklyImagesData[1].thumbnail_url" class="rounded-3xl absolute left-[25%] md:left-[85px]"
                  style="z-index: 2; outline: 8px solid white; height: 125px !important;" width="125" />

                <img v-if="weeklyImagesData[2] && weeklyImagesData.length > 2 && weeklyImagesData[2].thumbnail_url"
                  :src="weeklyImagesData[2].thumbnail_url" class="rounded-3xl absolute left-[50%] md:left-[170px]"
                  style="z-index: 3; outline: 8px solid white; height: 125px !important;" width="125" />

                <!-- Semi-transparent black cover for the 3rd image -->
                <div v-if="weeklyImagesData.length > 3"
                  class="rounded-3xl absolute bg-black bg-opacity-50 left-[50%] md:left-[170px]"
                  style="z-index: 4; height: 125px; width: 125px;"></div>

                <!-- Image count on top of black cover  -->
                <div v-if="weeklyImagesData.length > 3" class="absolute z-10 text-white text-5xl"
                  style="left: calc(180px + 70px - 50px); top: 57px;">
                  +{{ weeklyImagesData.length - 3 }}
                </div>
              </div>

              <!-- Description -->
              <!-- <div class="text-slate-600">{{ weeklyImagesData[0] && weeklyImagesData[0].description }}</div> -->
            </div>

           </div>

            <!-- Learner profiles cards -->
            <div class="w-full md:w-[370px] h-auto max-h-[300px] overflow-auto border border-slate-200 rounded-xl px-4 py-4 mt-6">

              <div class="font-medium mb-4">Learners Profile</div>

              <!-- Learner -->
              <div v-for="(learner, index) in learnerAndUserProfileDetails.learners" :key="index">
                <router-link :to="{ path: '/front-portal/learners', query: { learner: learner.name}}"  class="flex gap-2 border border-slate-200 pl-4 rounded-lg pt-3 pb-3 mt-4">                          
                    <!-- pfp -->
                    <img :src=learner.presigned_s3_url class="rounded-full w-16 h-16" alt="Profile Picture">
                    <!-- Info -->
                    <div>
                      <!-- Name -->
                      <span class="font-medium mr-2 text-sm">{{ learner.student_name }}</span>

                      <!-- Age -->
                      <div>
                        <span class="font-medium mr-2 text-sm">Age:</span>
                        <span class="text-slate-500 text-sm">{{ calculateAge(learner.date_of_birth)}}</span>
                      </div>

                      <!-- Cohort -->
                      <div>
                        <span class="font-medium mr-2 text-sm">Cohort:</span>
                        <span class="text-slate-500 text-sm">{{ learner.cohort_name }}</span>
                      </div>
                    </div>
                </router-link>
              </div>

            </div>


        </div>

        <!-- Third column -->
        <div class="w-full md:w-auto mt-6 md:mt-0">

          <!-- Worksheet widget -->
          <div v-if="worksheetTitles.length>0" class="border border-slate-200 rounded-xl overflow-auto px-4 py-4 mb-6">
            
            <!--Header row  -->
            <div class="flex justify-between">
              <div class="font-medium mr-2">
                Worksheets
              </div>
              <router-link to="/front-portal/worksheets"                         
              class="text-white bg-black rounded text-center text-xs py-2 px-3"> 
                View All
              </router-link>
            </div>

            <!-- Worksheet titles -->
            <!-- <template v-if="worksheetTitles"> -->
              <div v-for="(worksheet, index) in worksheetTitles" :key="index">
                <router-link :to="{ name: 'WorksheetDetails', params: { worksheetName: worksheet.worksheet_id } }" class="flex gap-2 border border-slate-200 pl-4 rounded-lg  py-5 mt-4">
                <!-- Worksheet title-->
                <div class="font-medium mr-2 text-sm">{{ worksheet.title }}</div>
                </router-link>
              </div>
            <!-- </template>    -->

          </div> 

          <!--Reports Card -->
          <div v-if="learnerAndUserProfileDetails.learners.length>0 && learnerAndUserProfileDetails.learners[0].report_status.is_published === 1" class="border border-slate-200 rounded-xl px-4 py-4 mb-6">
            
            <!-- Header row -->
            <div class="flex justify-between">
              <div class="font-medium">
                Learners Reports
              </div>
              <router-link to="/front-portal/observations/report"                         
                class="text-white bg-black rounded text-center text-xs py-2 px-3"> 
                  View All
              </router-link>
            </div>

            <!-- Reports button -->
              <div v-for="(learner, index) in learnerAndUserProfileDetails.learners">
                <template v-if="learner.report_status.is_published === 1"> 
                  <router-link :to="{ name: 'StudentReport', params: { student: learner.name } }" class="flex gap-2 border border-slate-200 pl-4 rounded-lg  py-5 mt-4">
                    <div class="font-medium mr-2 text-sm"> {{ learner.student_name }}'s Report</div>
                  </router-link>
                </template> 
              </div>

          </div> 
          
           <!-- Micro Learnings Card-->
           <div v-if="sortedQuizList.length>0" class="md:w-[370px] h-auto border border-slate-200 rounded-xl overflow-hidden px-4 py-4">

              <!--Header row  -->
              <div class="flex justify-between mb-3">
                <div class="font-medium mr-2">
                  Micro Learning
                </div>
                <router-link to="/front-portal/educators"                         
                class="text-white bg-black rounded text-center text-xs py-2 px-3"> 
                  View All
                </router-link>
              </div>

              <!-- Render list of quetionnairs -->
              <div v-if="sortedQuizList" class="flex flex-col md:flex-row md:flex-wrap gap-4">
                <LearningCard v-for="quiz in sortedQuizList.slice(0, 2)" :key="quiz.name" :title="quiz.title"
                  :description="quiz.description" :qID="quiz.name" :updateScore="setLastScore" class="text-sm mb- learning-card-wrapper"/>
              </div>

           </div>

        </div>


    </div>

  </div>
  <div v-else class="absolute inset-0 flex items-center justify-center md:mt-12">
      <div style="color: #FDD835"
        class="animate-spin inline-block size-12 border-[3px] border-current border-t-transparent text-yellow-600 rounded-full"
        role="status" aria-label="loading">
      <span class="sr-only">Loading...</span>
    </div>
  </div>


</template>

<script setup>
import { ref, onMounted, inject, computed, watch } from 'vue';
import moment from 'moment';
import LearningCard from '../components/educators/LearningCard.vue';
import CompletionModal from '../components/educators/CompletionModal.vue';
import LearningCardAttempted from '../components/educators/LearningCardAttempted.vue';
import { createResource } from 'frappe-ui'
import { cn } from '@/components/ui/utils.js';



    const weeklyImagesData = ref([]);
    const uniqueTags = ref([]);
    const learnerAndUserProfileDetails = ref([]);
    const dailyObservations = ref([]);
    const fetchedCalendarWidgetEvents = ref([]);
    const worksheetTitles = ref([])
    const lunchMenuData = ref([])
    
    // Inject the $call method
    const $call = inject('$call');

    const scheduleContainer = ref(null);

    const scrollUp = () => {
      if (scheduleContainer.value) {
        scheduleContainer.value.scrollBy({
          top: -50, // Adjust the value for how much you want to scroll up
          behavior: 'smooth',
        });
      }
    };

    const scrollDown = () => {
      if (scheduleContainer.value) {
        scheduleContainer.value.scrollBy({
          top: 50, // Adjust the value for how much you want to scroll down
          behavior: 'smooth',
        });
      }
    };


    // funtion to fetch images for photo essays widget
    const fetchRecentImages = async () => {
      try {
        const response = await $call('curriculum_planner.curriculum_planner.doctype.upload_images.upload_images.get_recent_images_for_parent_dashboard');
        weeklyImagesData.value = response;
        collectUniqueTags();
        console.log("Image data fetched in parent view", response);
      } catch (error) {
        console.error('Error fetching recent images:', error);
      }
    };

    //Funtion to fetch Lunch Menu for widget  
    const fetchTodaysLunchMenu = async () => {
    try {
      const response = await $call('curriculum_planner.curriculum_planner.doctype.lunch_menu.api.get_lunch_menu_for_parent_dashboard');
      if (response.status === 'success') {
        // Update your component's state with the fetched data
        lunchMenuData.value = response
        console.log("w:this is data recieved for lunch menu in parent dashboard",lunchMenuData.value)
      } else {
        console.error('Failed to fetch today\'s lunch menu:', response.message);
      }
    } catch (error) {
      console.error('Error fetching today\'s lunch menu:', error);
    }
  };

    // Function to collect unqiue tags for photo-essays widget
    const collectUniqueTags = () => {
      const tagsSet = new Set();
      for (let i = 0; i < 3; i++) {

        if (weeklyImagesData.value[i] && weeklyImagesData.value[i].tags.length > 0) {

          for (let tag of weeklyImagesData.value[i].tags) {

            if (!tagsSet.has(tag) && tagsSet.size < 3) {
              tagsSet.add(tag);
            }

          }

        }
      }
      uniqueTags.value = Array.from(tagsSet);
      console.log("These are unique tags -----------", uniqueTags.value)
    };

    // funtion to fetch user details
    const fetchProfileDetails = async () => {
      try {
        const response = await $call('curriculum_planner.curriculum_planner.common.user_details.api.fetch_learner_and_user__profile_details');
        learnerAndUserProfileDetails.value = response;

        console.log("This is response of learner and user profile", learnerAndUserProfileDetails.value)

      } catch (error) {
        console.error('Error fetching recent images:', error);
      }
    };

    // funtion to fetch worksheet titles for worksheets widget
    const fetchWorksheetTitles = async () => {
      try {
        const response = await $call('curriculum_planner.curriculum_planner.doctype.worksheet.api.fetch_worksheet_titles_for_parent_dashboard');
        worksheetTitles.value = response;

        console.log("This is response of fetching worksheet titles in parent dashboard", response)

      } catch (error) {
        console.error('Error fetching worksheet titles in parent dashboard:', error);
      }
    };

    

      // Funtion for Obervations widget
      const filteredObservations = computed(() => {
        if (dailyObservations.value.length === 1) {
          const learner = dailyObservations.value[0];
          return learner.observations.slice(0, 2);
        } else {
          return dailyObservations.value.map(learner => learner.observations[0]);
        }
      });

      // Funtion to fetch curriculum events data for widgets
      const fetchCalendarWidgetData = async (forDate) => {
        const startDate = forDate
        const endDate = moment(forDate).add(3, 'days');

        console.log("Fetching data between", startDate, "and", endDate);

        const response = await $call('curriculum_planner.curriculum_planner.doctype.curriculum_events.curriculum_events.get_events_based_on_role_for_parent_dashboard', {
          start_date: startDate,
          end_date: endDate
        });
        fetchedCalendarWidgetEvents.value = response;
      };

      // Funtion to group events together
      const groupedEvents = computed(() => {
      if (fetchedCalendarWidgetEvents.value && fetchedCalendarWidgetEvents.value.enriched_events) {
        
        console.log("w:this is fetch calendar events",fetchedCalendarWidgetEvents.value)
        // const parentCohorts = new Set(fetchedCalendarWidgetEvents.value.parents_cohorts_info.name || []);
        const parentCohorts = new Set(fetchedCalendarWidgetEvents.value.parent_cohorts_info.map(cohort => cohort.name) || []);
        const startOfWeek = moment().startOf('week');
        const endOfWeek = moment().endOf('week');

        const grouped = fetchedCalendarWidgetEvents.value.enriched_events.reduce((acc, event) => {
          const date = event.start.split(" ")[0];
          if (!acc[date]) {
            acc[date] = {};
          }

          event.cohorts.forEach(cohort => {
            if (parentCohorts.has(cohort)) {
              if (!acc[date][cohort]) {
                acc[date][cohort] = {
                  Morning: [],
                  Afternoon: [],
                };
              }
              acc[date][cohort][event.session].push(event);
            }
          });

          return acc;
        }, {});
          let dayCounter = 0;
          // Ensure all weekdays (Monday to Friday) of the current week are included --Updated value of 'day' to current day to not group/render previous days
          for (let day = moment(); day.isBefore(endOfWeek) && dayCounter <= 2; day.add(1, 'day')) {
            const dateString = day.format('YYYY-MM-DD');
           
            // Skip weekends
            if (day.isoWeekday() <= 5 && !grouped[dateString]) {  
              grouped[dateString] = {}; // Include empty weekday
             
            }
             dayCounter++;
          }
          // Convert the grouped object to an array of key-value pairs
          const groupedArray = Object.entries(grouped);

          // Sort the array based on the date in ascending order
          groupedArray.sort(([date1], [date2]) => new Date(date1) - new Date(date2));

          // Convert the sorted array back to an object
          const sortedGrouped = Object.fromEntries(groupedArray);
          return sortedGrouped;
        }
        return {};
      });

 
      // For highlighting the dates in calendar widget
      const isToday = (date) => {
        return moment(date).isSame(moment(), 'day');
      };

      
      // Fetching quicklearning widget data here
      const quizListResource = createResource({
        url: '/api/method/curriculum_planner.curriculum_planner.doctype.facilitator_questionnaire.api.get_questionnaires',
        auto: true
      })
      
      const sortedQuizList = computed(() => {
        if (!quizListResource.data?.quiz_list) return []
        return quizListResource.data.quiz_list.toSorted((a, b) => {
          if (a.is_attempted && b.is_attempted) return 0
          else if (a.is_attempted) return 1
          else return -1
        })
      })

      const sortedAttemptQuiz = computed(() => {
        if (!quizListResource.data?.attempted_list) return []
        return quizListResource.data.attempted_list
      })

      const isNotEducator = computed(() => {
        if (quizListResource.data && quizListResource.data.status === 'fail')
          return true
        return false
      })

      const calculateAge = (dateOfBirth) => {
        const now = new Date()
        const dob = new Date(dateOfBirth)
        const yearsDiff = now.getFullYear() - dob.getFullYear()
        const monthsDiff = now.getMonth() - dob.getMonth()
        
        let years = yearsDiff
        let months = monthsDiff

        if (monthsDiff < 0 || (monthsDiff === 0 && now.getDate() < dob.getDate())) {
          years--
          months = 12 + monthsDiff
        }

        if (years === 0) {
          return `${months} month${months !== 1 ? 's' : ''}`
        } else if (months === 0) {
          return `${years} year${years !== 1 ? 's' : ''}`
        } else {
          return `${years} year${years !== 1 ? 's' : ''} ${months} month${months !== 1 ? 's' : ''}`
        }
      }

      const getLearnerNames = () => {
        const result = learnerAndUserProfileDetails.value.learners.map(learner => learner.name).join('-');
        return result;
      }

      const lastScore = ref(0)

      const setLastScore = (score) => {
        lastScore.value = score
      }

      // Add a watcher on quizListResource.data
      watch(
        () => quizListResource.data,
        (newData) => {
           }
      );


    onMounted(() => {
      fetchProfileDetails();
      fetchRecentImages();
      fetchWorksheetTitles();
      // fetchDailyObservations();
      fetchCalendarWidgetData(moment().format('YYYY-MM-DD')) // Date format should be YYYY-MM-DD
      fetchTodaysLunchMenu();
    });

  

</script>

<style scoped>

.learning-card-wrapper{
  width: 100%;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.learning-card-wrapper ::v-deep .start-learning-btn {
  font-size: 11.5px;
  padding: 7px;
  background-color:rgba(253, 216, 53, 1);
  color: black;
  min-width: 100px;
  margin-bottom: auto;
  /* Add more custom styles as needed */
}
.learning-card-wrapper ::v-deep(.custom-title) {
  font-size: 14px;
  line-height: 23px;
  font-weight: 500;
  color: black;
}
.learning-card-wrapper ::v-deep(.custom-description-and-btn-container) {
  display: flex;
  flex-direction: row;
  flex-direction: row-reverse;
 
}
.learning-card-wrapper ::v-deep(.custom-description-container) {
  display: none; 
}

.show-photo-essays-widget{
    display: none;
 }

.hide-worksheet-widget{
    display: none;
 }

@media screen and (max-width: 769px) {
  
  /* width */
  ::-webkit-scrollbar {
    width: 2px;
    height: 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
}


@media screen and (max-width: 1451px) {
  .parent-dashboard-widgets-container{
    grid-template-columns: 1fr 1fr;
  
  }
  .widget-container-parent{
    max-width: 72%;
  }

  .show-photo-essays-widget{
    display: flex;
  }

  .show-worksheet-widget{
    display: flex;
  }
  .hide-widget{
    display: none;
  }

}
  

</style>