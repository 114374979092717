<template>
  <Card @click="viewObservation" class="transition-all cursor-pointer hover:brightness-95 rounded">
    <template #title>
      {{ findValueByName(userDetails?.school_info.students, props.observation.student, 'student_name') }}
      <!-- <span v-if="props.version === 2 && props.observation.additional_students?.length">
        + {{ props.observation.additional_students.length }} more
      </span> -->
      <span v-if="props.observation.is_archived" class="text-sm text-gray-600"> (archived)</span>
    </template>
    <div class="flex flex-col gap-2">
      <div class="flex justify-between">
        <div class="text-sm text-gray-600 flex gap-1 items-center">
          <span class="font-medium">{{ props.observation.date }}</span>
          <span class="text-lg">|</span>
          <span class="font-medium">
            {{ findValueByName(userDetails?.school_info.educators, props.observation.educator, 'educator_name') }}
          </span>
        </div>
        <div v-if="props.observation.is_imported" class="text-xs text-gray-600">
          imported
        </div>
      </div>
      <!-- <p class="text-ellipsis line-clamp-2 font-light text-gray-700 mt-2">
        {{ props.observation.anecdotes }}
      </p> -->
    </div>
  </Card>
</template>

<script setup>
import Card from '@/components/ui/Card.vue'
import { findValueByName } from '@/lib/utils'
import { HSOverlay } from 'preline/preline';
import { inject } from 'vue';

const props = defineProps(['observation', 'selectObservation', 'version'])

const userDetails = inject('userDetails')

const viewObservation = () => {
  props.selectObservation(props.observation, props.version)
  // HSOverlay.open('#hs-weekly-child-edit-modal-all')
}
</script>