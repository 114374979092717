<template>
  <div class="flex flex-col max-h-[94dvh] md:h-[91.5vh] pt-6 px-2 lg:py-8 md:px-16 pb-20
    gap-4 lg:gap-8 lg:pr-28 overflow-y-auto">
    <div class="flex">
      <div class="flex flex-col gap-4 lg:gap-8">
        <h2 class="text-3xl">Learner Observations</h2>
        <p class="text-sm">
          Please do add observations regularly. Observations are how we assess where we are and what to do next.
        </p>
      </div>
    </div>
    <div class="flex md:flex-row gap-2 md:gap-8 md:items-center">
      <router-link to="/front-portal/observations/statistics" v-if="userDetails?.is_educator || userDetails?.is_system_manager">
        <button class="py-3 px-4 inline-flex items-center gap-x-2
          text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
          hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit"
          type="button">
          View Observation Statistics
        </button>
      </router-link>
      <AddNewDropdown :reloadObservations="reloadInitialObservations" />
    </div>
    <ObservationTabHeader v-if="userDetails?.is_educator || userDetails?.is_system_manager || userDetails?.user_roles.includes('Guest Facilitator')"
      :observTab="observTab" :switchTab="switchTab" />
    <div v-if="userDetails?.is_educator || userDetails?.is_system_manager || userDetails?.user_roles.includes('Guest Facilitator')" 
      class="flex flex-col lg:flex-row gap-4 lg:items-start">
      <ChildObservCard :userData="userData" :observTab="observTab" :observeData="initialData" 
        :reloadObservations="reloadInitialObservations" />
      <ActivityObservCard :userData="userData" :observTab="observTab" :observeData="initialData"
        :reloadObservations="reloadInitialObservations" />
      <UnstructuredObservCard :userData="userData" :observTab="observTab" :observeData="initialData"
        :reloadObservations="reloadInitialObservations" />
      <WeeklyChildObservCard :observeData="initialData" :reloadObservations="reloadInitialObservations" />
    </div>
    <div v-else-if="userDetails?.user_roles?.includes('Parent') && 
      !(userDetails?.is_educator || userDetails?.is_system_manager || userDetails?.user_roles.includes('Guest Facilitator'))" 
      class="flex flex-col xl:flex-row gap-4 xl:items-start lg:basis-[80%] lg:max-h-[80%]">
      <ParentChildObservCard :userData="userData" :observTab="observTab" :observeData="initialData"
      :reloadObservations="reloadInitialObservations" />
    </div>
    <!-- <button @click="toggleModal"> New Activity Obsevation</button>
    <ActivityObservations
      v-if="isModalOpen"
      @close="toggleModal"
    /> -->
  </div>
</template>

<script>
import ActivityObservations from '../components/ActivityObservations.vue'
import ChildObservCard from '../components/observations/ChildObservCard.vue';
import ActivityObservCard from '../components/observations/ActivityObservCard.vue';
import UnstructuredObservCard from '../components/observations/UnstructuredObservCard.vue';
import ObservationTabHeader from '../components/observations/view/ObservationTabHeader.vue';
import ParentChildObservCard from '@/components/observations/ParentChildObservCard.vue';
import { computed } from 'vue';
import 'floating-vue/dist/style.css';
import WeeklyChildObservCard from '../components/observations/WeeklyChildObservCard.vue';
import AddNewDropdown from '../components/observations/forms/AddNewDropdown.vue';

export default {
  inject: ["$auth", "$call", 'userDetails'],
  provide() {
    return {
      observationCompetencies: computed(() => this.competencies)
    }
  },
  components: {
    ActivityObservations,
    ChildObservCard,
    ActivityObservCard,
    UnstructuredObservCard,
    ObservationTabHeader,
    ParentChildObservCard,
    WeeklyChildObservCard,
    AddNewDropdown
},
  data() {
    return {
      isModalOpen: false,
      userData: null,
      observTab: 'child',
      initialData: null,
      competencies: null,
    };
  },
  methods: {
    toggleModal() {
      this.isModalOpen = !this.isModalOpen;
    },
    async getUserDetails() {
      try {
        const response = await this.$call(
          'curriculum_planner.curriculum_planner.doctype.daily_observations.api.get_user_details'
        )
        
        if (response.status === 'success')
          return response.user_details
        console.error('Error fetching user details:', response.message)
      } catch (error) {
        console.error('Error fetching user details: ', error)
      }
    },
    switchTab(tabName) {
      this.observTab = tabName
    },
    async getInitialObservations() {
      try {
        const response = await this.$call(
          'curriculum_planner.curriculum_planner.common.observations.api.get_observation_overview'
        )

        console.log(response)
        if (response.status === 'success')
          return response.observation_data
        console.error('Error fetching observation data:', response.message)
      } catch (error) {
        console.error('Error fetching observation data: ', error)
      }
    },
    reloadInitialObservations() {
      this.getInitialObservations()
        .then((data) => {
          this.initialData = data
          this.competencies = data.competencies
        })
    }
  },
  mounted() {
    this.getUserDetails()
      .then((details) => {
        this.userData = details
      })
    this.getInitialObservations()
      .then((data) => {
        this.initialData = data
        this.competencies = data.competencies
      })
  }
};
</script>
