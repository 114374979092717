<template>
  <slot name="trigger" />

  <div :id="`hs-${props.modalName}`" class="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 
    hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto 
    pointer-events-none flex flex-col justify-center items-center">
    <div :class="cn(`hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all 
       w-[95%] m-6 min-h-2/3 lg:w-2/3 xl:w-1/2`)">
       <!-- sm:w-full w-[95%] m-3 min-h-2/3 max-h-[80vh] lg:w-1/2 overflow-y-visible`,
       (pageNum === 2 || pageNum === 7 || pageNum === 8) && 'overflow-y-auto')"> -->
      <div :class="cn(`flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto h-full`)" ref="scrollContainer">
        <!-- (pageNum === 2 || pageNum === 7 || pageNum === 8) && 'overflow-y-auto')" ref="scrollContainer"> -->
        <div class="space-y-2">
          <div class="flex justify-between items-center px-4 pt-4">
            <h3 class="font-semibold text-[#6B7280] text-lg md:text-xl">
              Group/Activity Observation
            </h3>
            <button type="button" class="flex justify-center items-center size-7 text-sm font-semibold
              rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50
              disabled:pointer-events-none" :data-hs-overlay="`#hs-${props.modalName}`">
              <span class="sr-only">Close</span>
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
            </button>
          </div>
          <DateDisplay :date="selectedDate" />
          <div class="px-4 flex hidden">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.23828 7.87968C1.23828 8.79313 1.4182 9.69764 1.76776 10.5416C2.11733 11.3855 2.62969 
                12.1523 3.2756 12.7982C3.92151 13.4441 4.68832 13.9565 5.53224 14.306C6.37616 14.6556 7.28068 
                14.8355 8.19413 14.8355C9.10759 14.8355 10.0121 14.6556 10.856 14.306C11.6999 13.9565 12.4667 
                13.4441 13.1127 12.7982C13.7586 12.1523 14.2709 11.3855 14.6205 10.5416C14.9701 9.69764 15.15 
                8.79313 15.15 7.87968C15.15 6.03487 14.4171 4.26562 13.1127 2.96115C11.8082 1.65667 10.0389 
                0.923828 8.19413 0.923828C6.34932 0.923828 4.58008 1.65667 3.2756 2.96115C1.97113 4.26562 
                1.23828 6.03487 1.23828 7.87968Z" stroke="#6B7280" stroke-width="1.54574" stroke-linecap="round" 
                stroke-linejoin="round"/>
              <path d="M8.19531 4.01465V7.87901L10.5139 10.1976" stroke="#6B7280" stroke-width="1.54574" 
                stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        <form @submit.prevent="handleSubmit" class="flex-auto flex flex-col font-normal" ref="formRef">
          <div :class="cn(`max-h-[60vh]`, ( pageNum !== 2) &&  'overflow-y-auto')">
            <ActivityFormPage1 :pageNum="pageNum" :userData="userDetails" 
              @update:cohort="handleCohortUpdate" ref="page1Ref" @date:selected="updateDate" />
            <ActivityFormLinkPage :pageNum="pageNum" :componentPageNum="2" :observation="eventPageObservation"
              :events="curriculumEventsList" @change:value="(newValue) => selectedCurriculumEvent = newValue" />
            <ActivityFormAdditionalPage v-if="userDetails" :pageNum="pageNum" :componentPageNum="3" 
              :studentList="userDetails.school_info.enrolled_students" :selectedCohorts="engageCohorts" />
            <ActivityFormCorePage :pageNum="pageNum" :componentPageNum="4"
              :userData="userDetails" />
            <ActivityFormSummaryPage :pageNum="pageNum" :componentPageNum="5" ref="page2Ref"
              :userData="userDetails" />
            <ActivityFormPrimaryPage v-if="competencyList.data" :pageNum="pageNum" :componentPageNum="6" 
              ref="page4Ref" :competencyList="competencyList.data" :version="2" 
              @changed:text-mode="changePrimaryTextMode" :fetchedComps="fetchedComps"
              @decision:fetchedComps="handleFetchedCompsDecisionMade" 
              @selected:secondary="setSelectedFetchSecondaryComps" />
            <ActivityFormSecondaryPage v-if="competencyList.data" :pageNum="pageNum" :componentPageNum="7" 
              ref="page5Ref" :competencyList="competencyList.data" :fetchedComps="selectedFetchSecondaryComps" />
            <ActivityFormEngagePage :pageNum="pageNum" :componentPageNum="8" :cohorts="engageCohorts"
              :additionalStudents="additionalStudents" ref="page3Ref"/>
            <!-- <ActivityFormEvidencePage :pageNum="pageNum" :componentPageNum="8" ref="page6Ref" /> -->
            <ActivityFormRatingPage :pageNum="pageNum" :componentPageNum="9" ref="page7Ref" />
            <!-- <ActivityFormWorthPage :pageNum="pageNum" :componentPageNum="9" /> -->
            <ActivityFormWorthPage2 :pageNum="pageNum" :componentPageNum="10" />
            <ActivityFormNotesPage :pageNum="pageNum" :componentPageNum="11" :userData="userDetails"
              :selectedCohorts="engageCohorts" :studentsList="userDetails.school_info.enrolled_students"
              :additionalStudents=additionalStudents v-if="userDetails" />
            <CompReplace v-if="customPrimaryText && compMapResource.data?.status === 'success'" 
              :pageNum="pageNum" :componentPageNum="12" choiceInputName="keep_replace_primary"
              :generatedCompList="compMapResource.data.primary_competencies" observType="activity"
              :customText="customPrimaryText" :competencyList="competencyList.data" 
              @compListModified="handleprimaryCustomUpdate"/>
            <CompReplace v-if="customText && compMapResource.data?.status === 'success' && competencyList.data" 
              :pageNum="pageNum" :componentPageNum="13" choiceInputName="keep_replace"
              :generatedCompList="compMapResource.data.secondary_competencies" observType="activity"
              :customText="customText" :competencyList="competencyList.data" 
              @compListModified="handleSecondaryCustomUpdate"/>
          </div>
          <div :class="cn(`text-xs md:text-sm font-semibold text-red-400 p-3 text-center`, 
            hideInputWarning && 'hidden')">
            Please answer all the required questions!
          </div>
          <div :class="cn(`text-xs md:text-sm font-semibold text-red-400 p-3 text-center`, 
            hideCustomInputWarning && 'hidden')">
            {{ customInputWarning }}
          </div>
          <div :class="cn(`text-sm font-semibold text-gray-600 p-3 text-center`, 
            !isFetchingObservComps && 'hidden')">
            Getting Competency suggestions...
          </div>
          <div class="flex flex-col lg:flex-row gap-4 lg:items-center lg:justify-between">
            <div class="flex gap-2 items-center">
              <div class="px-8 md:px-4 flex gap-1 items-center">
                <div v-for="n in totalPageNum" :class="cn(`h-2 w-3 bg-[#F3F4F6]`, n <= pageNum && 'bg-[#FFD700]')">
                  <!-- <div :class="cn(`h-full w-full`, n <= pageNum && 'bg-[#FFD700]')"></div> -->
                </div>
              </div>
              <div class="text-sm font-semibold text-gray-600">
                {{ pageNum }}/{{ totalPageNum }}
              </div>
            </div>
            <div class="flex justify-center sm:justify-end items-center gap-x-4 py-3 px-4">
              <button type="button" class="py-2.5 px-8 md:px-14 inline-flex items-center gap-x-2 text-sm font-medium
                rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
                disabled:opacity-50 disabled:pointer-events-none" @click="goToPrevPage" :disabled="pageNum === 1">
                Back
              </button>
              <button type="button" :class="cn(`py-2.5  px-8 md:px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-md border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, pageNum === totalPageNum && 'hidden')" 
                @click="goToNextPage" :disabled="pageNum === totalPageNum">
                Next
              </button>
              <button type="submit" :class="cn(`py-2.5 px-8 md:px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-md border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, pageNum !== totalPageNum && 'hidden')" 
                :disabled="(pageNum !== totalPageNum) || isSubmitting">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, computed, nextTick, ref } from 'vue'
import { cn } from '@/components/ui/utils.js'
import ActivityFormPage1 from './ActivityForm/ActivityFormPage1.vue';
import ActivityFormSummaryPage from './ActivityForm/ActivityFormSummaryPage.vue';
import ActivityFormCorePage from './ActivityForm/ActivityFormCorePage.vue';
import ActivityFormPrimaryPage from './ActivityForm/ActivityFormPrimaryPage.vue';
import ActivityFormSecondaryPage from './ActivityForm/ActivityFormSecondaryPage.vue';
import ActivityFormEvidencePage from './ActivityForm/ActivityFormEvidencePage.vue';
import ActivityFormRatingPage from './ActivityForm/ActivityFormRatingPage.vue';
import ActivityFormEngagePage from './ActivityForm/ActivityFormEngagePage.vue'
import ActivityFormLinkPage from './ActivityForm/ActivityFormLinkPage.vue'
import { createResource, createListResource } from 'frappe-ui'
import { HSOverlay } from 'preline/preline';
import DateDisplay from './DateDisplay.vue';
import ActivityFormNotesPage from './ActivityForm/ActivityFormNotesPage.vue';
import ActivityFormWorthPage from './ActivityForm/ActivityFormWorthPage.vue';
import ActivityFormWorthPage2 from './ActivityForm/ActivityFormWorthPage2.vue';
import ActivityFormAdditionalPage from './ActivityForm/ActivityFormAdditionalPage.vue';
import CompReplace from './CompReplace.vue'

const props = defineProps([
  'modalName', 'userData', 'reloadFn',
  'reloadObservations', 'confirmModalName'
])

const pageNum = ref(1)
const totalPageNum = ref(11)
const engageCohorts = ref([])
const additionalStudents = ref([])
const selectedDate = ref(null)

const hideInputWarning = ref(true)
const hideCustomInputWarning = ref(true)
const customInputWarning = ref('')
const formRef = ref(null)
const page1Ref = ref(null)
const page2Ref = ref(null)
const page3Ref = ref(null)
const page4Ref = ref(null)
const page5Ref = ref(null)
const page6Ref = ref(null)
const page7Ref = ref(null)

const userDetails = inject(['userDetails'])

const setHideWarningTime = (time) => {
  setTimeout(() => {
    if (hideInputWarning.value === false)
      hideInputWarning.value = true
    if (hideCustomInputWarning.value === false)
      hideCustomInputWarning.value = true
  }, time)
}

const observationResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.activity_observations.api.create_new_observation'
})

const curriculumEventsResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.link.api.get_curriculum_events_from_date',
})

const curriculumEventsList = computed(() => {
  if (!curriculumEventsResource.data?.events) return []
  return curriculumEventsResource.data.events
})

const eventPageObservation = ref(null)
const selectedCurriculumEvent = ref('')

const competencyList = createListResource({
  doctype: 'Competency',
  fields: ['name','competency_name', 'description'],
  orderBy: 'competency_name asc',
  start: 0,
  pageLength: 90,
  auto: true
})

const compMapResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.api.get_matching_activity_competencies'
})
const customText = ref('')
const customPrimaryText = ref('')

const observCompsResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.api.get_observation_competency_maps'
})

const prevSummary = ref('')
const isFetchingObservComps = ref(false)
const fetchedComps = ref([])
const selectedFetchSecondaryComps = ref([])
const fetchedCompDecision = ref(false)
const fetchedCompDecisionMade = ref(false)
const primaryTextMode = ref(false)

const changePrimaryTextMode = (newValue) => {
  primaryTextMode.value = newValue
}

const setSelectedFetchSecondaryComps = (selectedCompList) => {
  selectedFetchSecondaryComps.value = selectedCompList
}

const scrollContainer = ref(null);

const scrollToTop = () => {
  scrollContainer.value.scrollTo({
    top: 0,
    behavior: 'smooth' // This adds a smooth scrolling effect
  });
}

const handleFetchedCompsDecisionMade = (decision) => {
  fetchedCompDecision.value = decision;
  fetchedCompDecisionMade.value = true
  console.log("Fetched comps decitions made(activity). decision: ", { decision})
  if (decision === true) {
    hideInputWarning.value = true;
    pageNum.value = 8;
    scrollToTop();
  }
}

const goToPrevPage = async () => {
  if (pageNum.value === 1) return
  pageNum.value = pageNum.value - 1
  await nextTick();
  scrollToTop();
}

const goToNextPage = async () => {
  if (pageNum.value === totalPageNum) return
  const formData = new FormData(formRef.value)
  if (pageNum.value === 1) { 
    const eduId = formData.get('educator_id')
    const date = formData.get('date')
    const cohort = formData.get('cohorts')
    const cohorts = formData.getAll('cohorts')
    const time = formData.get('activity_time')
    if (!(eduId && date && cohort && time)) {
      hideInputWarning.value = false
      return
    } else {
      hideInputWarning.value = true
    }
    eventPageObservation.value = {
      activity_time: time,
      date,
      cohorts,
      educator_name: eduId,
    }
    await curriculumEventsResource.submit({ date })
    console.log({Resource: curriculumEventsResource})
  } else if (pageNum.value === 2) {
    const linkedEvent = selectedCurriculumEvent.value;
    const is_not_linked = formData.get('is_not_event_linked');
    console.log("not linked", { is_not_linked })
    if (!linkedEvent && !is_not_linked) {
      hideCustomInputWarning.value = false
      customInputWarning.value = 'Please select an activity or check the above checkbox to proceed.';
      return
    } else {
      hideInputWarning.value = true
    }
  } else if (pageNum.value === 3) {
    const addStudents = formData.getAll('other_students')
    additionalStudents.value = addStudents
  } else if (pageNum.value === 4) {
    const core_idea = formData.get('core_idea')
    const is_unsure = formData.get('core_idea_type')
    if (!core_idea && !is_unsure) {
      hideInputWarning.value = false
      return
    } else {
      hideInputWarning.value = true
    }
  } else if (pageNum.value === 5) { 
    const summary = formData.get('summary')
    if (!summary) {
      hideInputWarning.value = false
      return
    } else {
      hideInputWarning.value = true
    }

    if (summary !== prevSummary.value) {
      prevSummary.value = summary
      isFetchingObservComps.value = true
      fetchedCompDecision.value = false
      await observCompsResource.submit({
        observation_text: summary,
      })
      if (observCompsResource.data?.status === 'success' && Array.isArray(observCompsResource.data?.competencies)) {
        console.log('Fetched comp array exists', observCompsResource)
        fetchedComps.value = observCompsResource.data.competencies
      }
      // totalPageNum.value = 4
      isFetchingObservComps.value = false
      isSubmitting.value = false
    }

  } else if (pageNum.value === 6) { 
    if (fetchedComps.value.length && !fetchedCompDecisionMade.value) {
      hideCustomInputWarning.value = false
      customInputWarning.value = 'Please select one of the options above to proceed to the next step.';
      return
    } else {
      hideInputWarning.value = true
    }
    const custom_primary_text = formData.get('custom_primary_competency') || ''
    if(!formData.get('primary_competency') && !custom_primary_text) {
      hideInputWarning.value = false
      return
    } else {
      hideInputWarning.value = true
    }
  } else if (pageNum.value === 7) {
    const custom_text = formData.get('custom_secondary_competencies') || ''
    const custom_primary_text = formData.get('custom_primary_competency') || ''
    // if(!formData.get('secondary_competencies') && !custom_text) {
    //   hideInputWarning.value = false
    //   setHideWarningTime(1500)
    //   return
    // }
    if (custom_text || custom_primary_text) {
      customText.value = custom_text
      customPrimaryText.value = custom_primary_text
      compMapResource.submit({
        primary_text: custom_primary_text,
        secondary_text: custom_text
      })
      totalPageNum.value = (custom_text && custom_primary_text) ? 13 : 12
    } else {
      totalPageNum.value = 11
    }
    // REMOVED PAGE
  // } else if (pageNum.value === 8) {
  //   if(!formData.get('creativity_evidence')) {
  //     hideInputWarning.value = false
  //     setHideWarningTime(1500)
  //     return
  //   }
  } else if (pageNum.value === 8) {
    let allEngageMarked = true;
    additionalStudents.value.forEach((student) => {
      const studentEngagement = formData.get(`engage-${student}`)
      if (!studentEngagement) {
        allEngageMarked = false;
      }
    })
    const cohorts = formData.getAll('cohorts')
    userDetails.value?.school_info?.enrolled_students.forEach((student) => {
      if (cohorts.includes(student.cohort)) {
        const studentEngagement = formData.get(`engage-${student.name}`)
        if (!studentEngagement) {
          allEngageMarked = false;
        }
      }
    })

    if (!allEngageMarked) {
      hideCustomInputWarning.value = false
      customInputWarning.value = 'You missed marking the Engagement Level for all the learners! Scroll down to check missed learners.';
      return
    } else {
      hideInputWarning.value = true
    }
  } else if (pageNum.value === 9) {
    if (!formData.get('activity_rating')) {
      hideInputWarning.value = false
      return
    } else {
      hideInputWarning.value = true
    }
    // REMOVED PAGE
  // } else if (pageNum.value === 9) {
  //   if (!formData.get('worth_repeating')) {
  //     hideInputWarning.value = false
  //     setHideWarningTime(1500)
  //     return
  //   }
  } else if (pageNum.value === 10) {
    if (!formData.get('worth_building')) {
      hideInputWarning.value = false
      return
    } else {
      hideInputWarning.value = true
    }
  }
  hideInputWarning.value = true
  pageNum.value = pageNum.value + 1
  scrollToTop();
}

const handleCohortUpdate = (selectedCohorts) => {
  engageCohorts.value = selectedCohorts
}

const updateDate = (date) => {
  selectedDate.value = date
}

const secondaryCustomComps = ref([])
const handleSecondaryCustomUpdate = (compList) => {
  secondaryCustomComps.value = compList
}

const primaryCustomComps = ref([])
const handleprimaryCustomUpdate = (compList) => {
  primaryCustomComps.value = compList
}

const isSubmitting = ref(false)

const handleSubmit = async (e) => {
  isSubmitting.value = true
  const formData = new FormData(e.target)

  const formDataObj = {};
  formData.forEach((value, key) => {
      // Reflect.has in favor of: object.hasOwnProperty(key)
      if(!Reflect.has(formDataObj, key)){
          formDataObj[key] = value;
          return;
      }
      if(!Array.isArray(formDataObj[key])){
          formDataObj[key] = [formDataObj[key]];    
      }
      formDataObj[key].push(value);
  });
  if (!Array.isArray(formDataObj.cohorts)) {
    formDataObj.cohorts = [formDataObj.cohorts]
  }
  if (!Array.isArray(formDataObj.secondary_competencies)) {
    formDataObj.secondary_competencies = formDataObj.secondary_competencies ? [formDataObj.secondary_competencies] : []
  }
  if (!Array.isArray(formDataObj.other_secondary)) {
    formDataObj.other_secondary = formDataObj.other_secondary ? [formDataObj.other_secondary] : []
  }
  if (formDataObj.primary_competency === 'Other') {
    formDataObj.primary_competency = { 
      competency: formDataObj.other_primary,
      is_id: true,
    }
  } else if(formDataObj.primary_competency) {
    // ignore undefined
    formDataObj.primary_competency = {
      competency: formDataObj.primary_competency,
      is_id: false,
    }
  }

  if (formDataObj.keep_replace === 'replace') {
    formDataObj.secondary_competencies = ['Other']
    formDataObj.other_secondary = secondaryCustomComps.value
  } else if(formDataObj.keep_replace === 'keep') {
    formDataObj.secondary_competencies = []
  }

  if (formDataObj.keep_replace_primary === 'replace') {
    formDataObj.primary_competency = {
      competency: primaryCustomComps.value[0],  // api return list of suggestions
      is_id: true,
    }
  }
    // need to add rest to secondary comp
    // primaryCustomComps.value.slice(1).forEach(comp => {
    //   formDataObj.secondary_competencies
    // })
    // if (primaryCustomComps.value.length > 1) {
    //   formDataObj.secondary_competencies
    // }

  // console.log(formDataObj)
  // isSubmitting.value = false
  // return

  // console.log("Form.get type", formData.get('core_idea_type'))
  // console.log("Core Idea type:", formDataObj.core_idea_type)
  // return
  if (!formDataObj.core_idea_type) {
    formDataObj.core_idea_type = 'Text'
  } else if (formDataObj.core_idea_type === 'on') {
    formDataObj.core_idea_type = 'Unsure'
  }

  formDataObj.event_link = selectedCurriculumEvent.value
  
  formDataObj.text_mode_primary = primaryTextMode.value

  observationResource.update({
    params: {
      observation_data: JSON.stringify(formDataObj),
  }})
  await observationResource.submit()

  console.log(observationResource)
  if (observationResource.data.status === 'success') {
    await HSOverlay.close(`#hs-${props.modalName}`)
    pageNum.value = 1
    isSubmitting.value = false
    HSOverlay.open(`#hs-form-confirm-modal-${props.confirmModalName}`)
    await new Promise(resolve => setTimeout(resolve, 250));
    if (props.reloadObservations) props.reloadObservations()
    if (props.reloadFn) props.reloadFn()
  } else if (observationResource.data.status === 'fail') {
    alert("Form Submission Failed. Make sure you have filled everything correctly.")
    isSubmitting.value = false
  }
}
</script>