<template>
  <div :class="cn('p-8 overflow-y-visible flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
  props.pageNum !== props.componentPageNum && `hidden`)" >
    <div class="space-y-3" @keydown.stop>
      <h4 class="font-semibold text-base md:text-lg">
        {{ props.sectionNumber}}. {{ props.section }}
      </h4>
      <label class="font-medium text-sm md:text-base" :for="`#${question.id}`">
        {{ props.questionNumber }}. {{ props.question.text }}
      </label>
      <textarea 
        :id="`${question.textFieldId}`"
        :name="props.question.textFieldId"
        rows="3"
        class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm
        focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
        placeholder="Enter text here..." 
        v-model="text" 
        ref="textInputRef"
        @input="textareaAutoHeight"
        @click="selectTextInput"
      ></textarea>
      <div :class="cn(`flex flex-col gap-y-2 md:gap-y-4`)">
        <div v-for="option in props.question.options" :class="cn(`flex`, option === 'Text' && 'hidden')">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="radio" class="shrink-0 mt-0.5 border-gray-200 
              rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
              :value="option" :name="props.question.id" v-model="selectedOption">
              {{ option }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { cn } from '@/components/ui/utils.js';

const props = defineProps([
  'question', 'section', 'sectionNumber',
  'questionNumber', 'pageNum', 'componentPageNum',
  'observation'
])

const text = ref(props.observation ? (props.observation[`${props.question.id}_text`] || '') : '')
const selectedOption = ref(props.observation ? props.observation[props.question.id] : 'Text');

const textInputRef = ref(null)
const textareaAutoHeight = () => {
  if (selectedOption.value !== 'Text') selectedOption.value = 'Text';
  const windowHeight = window.innerHeight
  const offsetTop = 2
  textInputRef.value.style.height = 'auto'
  let calculatedHeight = textInputRef.value.scrollHeight + offsetTop
  if (calculatedHeight > windowHeight/2.5)
    calculatedHeight = windowHeight/2.5
  textInputRef.value.style.height = `${calculatedHeight}px`
}

const selectTextInput = () => {
  if (selectedOption.value !== 'Text')
  selectedOption.value = 'Text'
}
</script>
